import React, {useRef, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Marker, useMap, GeoJSON} from "react-leaflet";
import {getCurrentLocation} from "lib/map";
import Layout from "components/Layout";
import Map from "components/Map";
import geoData from '../../static/data/geoJsonHr.json';
import {useSiteMetadata} from "../hooks";
import 'leaflet-boundary-canvas';

const LOCATION = {
    lat: geoData.lat,
    lng: geoData.lon,
};
const CENTER = [LOCATION.lat, LOCATION.lng];

const getBounds = geoData.boundingbox.map(i => parseFloat(i));
const bounds = [[getBounds[0], getBounds[2]], [getBounds[1], getBounds[3]]];

/**
 * MapEffect
 * @description This is an example of creating an effect used to zoom in and set a popup on load
 */

const MapEffect = ({markerRef}) => {
    const map = useMap();

    useEffect(() => {
        if (!markerRef.current || !map) return;

        map.setMinZoom(map.getZoom()-1);
        map.fitBounds(bounds, {padding: [20, 20]});
        map.setMaxBounds(bounds);
        map.setMinZoom(map.getZoom());

        (async function run() {
            const location = await getCurrentLocation().catch(() => LOCATION);

            const {current: marker} = markerRef || {};

            marker.setLatLng(location);
        })();
    }, [map, markerRef]);

    return null;
};


const IndexPage = () => {
    const {companyName, siteDescription} = useSiteMetadata();
    const markerRef = useRef();

    const mapSettings = {
        defaultBaseMap: "OpenStreetMap",
        bounds: bounds,
        zoomSnap: 0
    };

    const makeGeoJson = {
        "type": "FeatureCollection",
        "features": [
            {
                "type": "Feature",
                "geometry": geoData.geojson
            }
        ]
    };

    return (
        <Layout pageName="home">
            <Helmet>
                <title>{companyName} | {siteDescription}</title>
                <meta name="description" content={siteDescription}/>
            </Helmet>

            <Map {...mapSettings}>
                <MapEffect markerRef={markerRef}/>
                <Marker ref={markerRef} position={CENTER}/>
                <GeoJSON data={makeGeoJson}/>
            </Map>
        </Layout>
    );
};

export default IndexPage;
